//历史记录列表
export const historyCols = [
  {
    prop: 'tradeTime',
    label: '操作时间',
    minWidth: '200',
    slotName: 'tradeTimeSlot'
  },
  {
    prop: 'tradeAmount',
    label: '变更金额',
    minWidth: '200'
  },
  {
    prop: 'beforeRemainingAmount',
    label: '变更前余额',
    minWidth: '200'
  },
  {
    prop: 'remainingAmount',
    label: '变更后余额',
    minWidth: '200'
  },
  {
    prop: 'remark',
    label: '备注',
    minWidth: '200'
  },
  {
    prop: 'tradeType',
    label: '行为',
    minWidth: '200',
    slotName: 'actionSlot'
  }
]
